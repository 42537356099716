import BaseState from "Engine/base/states/BaseState";
import eAnimationTypes from '../animations/animationTypes';

export default class JackpotGameInState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    async start() {
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        if (this.active)
            this.complete();
        if (!PIXI.Assets.get(eAnimationTypes.JACKPOT)) {
            OPPreloader.showDelayedPreloader();
            await PIXI.Assets.load(eAnimationTypes.JACKPOT);
            OPPreloader.hideDelayedPreloader();
        }
        super.start();
        this.complete();
    }

}
