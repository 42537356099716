import * as PIXI from 'pixi.js';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { cloneArray, shuffle } from 'Engine/utils/array';
import FlashLib from 'flashlib_onlyplay';
import eSymbolIndex from '../symbols/eSymbolIndex';
import {eSounds} from "../enums/eSoundTypes";

export default class ReelsContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
    this.addListeners();
    this.initControllerReels();
  }

  init() {
    this._jokerSounudIsPlaying = false;
    this.hasScatter = false;
    this.coinsAvailableValues = this._getCoinsAvailableValues();

    this.ControllerReels = this.getChildByName('controllerReels');
    this.Antisipator = this.getChildByName('antisipator');

    this._drawMask();
  }

  initControllerReels() {
    this.ControllerReels.init(
      EntryPoint.GameModel.isBonusGameMode
        ? EntryPoint.GameModel.bonusGameAvalibleReels
        : EntryPoint.configData.reels.availableReelsNormal
    );
  }

  addListeners() {
    this.ControllerReels.onCycleAvailableSymbol = EntryPoint.GameModel.isBonusGameMode
      ? this.onCycleAvailableSymbol.bind(this)
      : () => {};
    this.ControllerReels.onCycleDataSymbol = EntryPoint.GameModel.isBonusGameMode
      ? this.onCycleDataSymbol.bind(this)
      : () => {};
    this.ControllerReels.onHittingBar = this.onHitBar.bind(this);
    this.ControllerReels.onReelStopped = this.onReelStopped.bind(this);
    this.ControllerReels.onAllReelsStopped = this.onAllReelsStopped.bind(this);
    GlobalDispatcher.add('winningsController:slowWin', this.onSlowWin, this);
    GlobalDispatcher.add('stateStart:bonusGameInState', this.onStartBonusGameInState, this);
    GlobalDispatcher.add('stateStart:bonusGameOutState',this.onStartBonusGameOutState, this);
    GlobalDispatcher.add('stateStart:showWinJackpotGameSymbolState', this.onShowWinJackpotGameSymbolState, this);
    GlobalDispatcher.add('stateManager:changed', this.onStateChanged, this);
  }

  onCycleAvailableSymbol(symbol) {
    if (symbol.id === eSymbolIndex.ESI_COIN) {
      symbol.setValue(this.coinsAvailableValues[0] * EntryPoint.GameModel.bet);
      this.coinsAvailableValues.unshift(this.coinsAvailableValues.pop());
    }
  }

  onCycleDataSymbol(symbol, reelId) {
    const value = this.coinSymbolsValues[reelId].pop();
    if (symbol.id === eSymbolIndex.ESI_COIN) {
      symbol.setValue(value * EntryPoint.GameModel.bet);
    }
  }

  onHitBar(reelIndex) {
    if (reelIndex === 1) this.Antisipator.tryStartAntisipator();
    if (reelIndex === 2) this.Antisipator.tryStopAntisipator();

    //hit coin
    if (EntryPoint.GameModel.bonusGameEnabled) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      for (let i = 1; i <= 3; i++) {
        const symbol = symbols[i];
        if(symbol.id === eSymbolIndex.ESI_COIN || symbol.id === eSymbolIndex.ESI_CROWN) {
          if (symbol.id === eSymbolIndex.ESI_CROWN) this.hasScatter = true;
          symbol.startStopAnimation();
          GlobalDispatcher.dispatch('specilalSymbol: hitTheBar', symbol); //transfer symbol
        }
      }
    }

    //hit joker
    const spacialSymbolIndex = EntryPoint.GameModel.winnings?.jackpotters?.length && EntryPoint.GameModel.winnings?.jackpotters[0]?.path[reelIndex];
    if (spacialSymbolIndex) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      this.hasScatter = true;
      GlobalDispatcher.dispatch('specilalSymbol: hitTheBar', symbols[spacialSymbolIndex]); //transfer symbol
    }

    //'stop sound'
    if (EntryPoint.GameSettings.quickStop && reelIndex === 2) {
      SoundManager.play(eSounds.EST_REEL_STOP.soundName, eSounds.EST_REEL_STOP.volume + 0.2 * Math.random(), false, eSounds.EST_REEL_STOP.group);
      if (this.hasScatter) {
        this.hasScatter = false;
        SoundManager.play(eSounds.EST_SCATTER_STOP.soundName, eSounds.EST_SCATTER_STOP.volume + 0.2 * Math.random(), false, eSounds.EST_SCATTER_STOP.group);
      }
    } else if (!EntryPoint.GameSettings.quickStop) {
      SoundManager.play(eSounds.EST_REEL_STOP.soundName, eSounds.EST_REEL_STOP.volume + 0.2 * Math.random(), false, eSounds.EST_REEL_STOP.group);
      if (this.hasScatter) {
        SoundManager.play(eSounds.EST_SCATTER_STOP.soundName, eSounds.EST_SCATTER_STOP.volume+ 0.2 * Math.random(), false, eSounds.EST_SCATTER_STOP.group);
        this.hasScatter = false;
      }
    }
  }

  onReelStopped(reelIndex) {
    const spacialSymbolIndex = EntryPoint.GameModel.winnings?.jackpotters?.length && EntryPoint.GameModel.winnings?.jackpotters[0]?.path[reelIndex];
    if (spacialSymbolIndex) {
      const symbols = this.ControllerReels.reels[reelIndex].symbols;
      symbols[spacialSymbolIndex].startStopAnimation();
    }
  }

  onAllReelsStopped() {
    GlobalDispatcher.dispatch('reelsContainer:reelsStopped');
  }

  onSlowWin(data) {
    this._showWinningSymbols(data);
  }

  onStartBonusGameInState() {
    this._setBonusGameReels();
  }

  onStartBonusGameOutState() {
    this._setMainGameReels();
  }

  onShowWinJackpotGameSymbolState() {
    this.ControllerReels.reels.forEach((reel) => {
      for (let i = 1; i < reel.symbols.length - 1; i++) {
        const symbol = reel.symbols[i];
        if (symbol.id === eSymbolIndex.ESI_CROWN) {
          symbol.onAnimationComplete = () => {
            GlobalDispatcher.dispatch('animationWinJackpotGameSymbol: complete');
            symbol.onAnimationComplete = () => {};
          };
          symbol.startAnimation();
        }
      }
    });
  }

  start() {
    this.ControllerReels.start();
    this._jokerSounudIsPlaying = false;
  }

  stop(data) {
    this.data = data;
    if (EntryPoint.GameModel.bonusGameEnabled) this.coinSymbolsValues = cloneArray(EntryPoint.GameModel.coinSymbolsValues)
    if (EntryPoint.GameSettings.quickStop) {
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.impactCoefficient = 0.25;
      this.ControllerReels.symbolsCountForStop = 3;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 1];
    } else {
      this.Antisipator.checkAntisipator(data);
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.symbolsCountForStop = this.Antisipator.isAntisipator ? [3, 8, 70] : [3, 8, 13];
      this.ControllerReels.impactCoefficient = 0.2;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 0.5];
    }
    this.ControllerReels.stop(this.data);
  }

  _getCoinsAvailableValues() {
    const coinsAvailableValues = [];
    EntryPoint.configData.coins.values.forEach((value, index) => {
      for (let i = 0; i < EntryPoint.configData.coins.weights[index]; i++) {
        coinsAvailableValues.push(value);
      }
    });
    return shuffle(coinsAvailableValues);
  }

  _setBonusGameReels() {
    this.ControllerReels.setAvailableSymbols(EntryPoint.GameModel.bonusGameAvalibleReels);
    this.ControllerReels.onCycleAvailableSymbol = this.onCycleAvailableSymbol.bind(this);
    this.ControllerReels.onCycleDataSymbol = this.onCycleDataSymbol.bind(this);
    this._lastReelsMainGame = this.ControllerReels.reels.map((reel) =>
      reel.symbols.map((symbol) => symbol.id)
    );
  }

  _setMainGameReels() {
    this.ControllerReels.setAvailableSymbols(EntryPoint.configData.reels.availableReelsNormal);
    this.ControllerReels.onCycleAvailableSymbol = () => {}
    this.ControllerReels.onCycleDataSymbol = () => {}
    if (this._lastReelsMainGame) {
      setTimeout(() => {
        this._lastReelsMainGame.forEach((reel, reelIndex) => {
          reel.forEach((symbolId, symbolIndex) => {
            const symbol = this.ControllerReels.reels[reelIndex].symbols[symbolIndex];
            symbol.changeSymbol(symbolId);
          });
        });
      }, 2000); // hide time bonusGameEnd popup
    }
  }

  _showWinningSymbols(data) {
    // this.hideWinningSymbols();
    data.params.path.forEach((pathData, index) => {
      if (pathData >= 0) {
        const symbol = this.ControllerReels.reels[index].symbols[pathData];
        symbol.startAnimation();
        if ((symbol.id === eSymbolIndex.ESI_JOKER || symbol.id === eSymbolIndex.ESI_BONUS_JOKER) && !this._jokerSounudIsPlaying ) {
          SoundManager.play(eSounds.EST_JOKER.soundName, eSounds.EST_JOKER.volume, false, eSounds.EST_JOKER.group);
          this._jokerSounudIsPlaying = true;
        }
      }
    });
  }

  _drawMask() {
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRoundedRect(-11, -9, 1065, 680, 62);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
  }
}
