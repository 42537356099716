import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import FlashLib from 'flashlib_onlyplay';
import animationTypes from '../animations/animationTypes';
import animationCreator from 'Engine/animations/animationCreator';
import eSymbolIndex from './eSymbolIndex';

export default class SymbolAnimation extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.animationNames = {
      [eSymbolIndex.ESI_WILD]: 'wild',
      [eSymbolIndex.ESI_BELL]: 'bell',
      [eSymbolIndex.ESI_WATERMELON]: 'watermelon',
      [eSymbolIndex.ESI_GRAPE]: 'grape',
      [eSymbolIndex.ESI_ORANGE]: 'orange',
      [eSymbolIndex.ESI_LEMON]: 'lemon',
      [eSymbolIndex.ESI_PLUM]: 'plum',
      [eSymbolIndex.ESI_CHERRY]: 'cherry',
      [eSymbolIndex.ESI_JOKER]: 'arlekin_idle',
      [eSymbolIndex.ESI_COIN]: 'coin_digit_idle',
      [eSymbolIndex.ESI_BONUS_JOKER]:'arlekin_idle',
      [eSymbolIndex.ESI_CROWN]: 'coin_crown_idle'
    };

    this.stopAnimationNames = {
      [eSymbolIndex.ESI_JOKER]: 'arlekin_stop',
      [eSymbolIndex.ESI_COIN]: 'coin_digit_stop',
      [eSymbolIndex.ESI_BONUS_JOKER]: 'arlekin_stop',
      [eSymbolIndex.ESI_CROWN]: 'coin_crown_stop'
    };

    this.onComplete = () => {};

    this.animationListener = {
      complete: () => {
        this._complete(this)
      }
    };

    this.animation = null;
    this.init();
    this.addListeners();
  }

  init() {
    this.animation = animationCreator.createAnimation(animationTypes.SYMBOLS);
    this.animation.visible = false;
    this.animation.state.addListener(this.animationListener);
    this.addChild(this.animation);
  }

  addListeners() {
    GlobalDispatcher.add('stateStart:jackpotGameInState', ()=> this.isActive = this.animation.visible, this);
    GlobalDispatcher.add('stateStart:jackpotGameOutState', () => this.animation.visible = this.isActive, this);
  }

  start(loop) {
    this._playSpineAnimation(loop);
  }

  _playSpineAnimation(loop) {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, this.animationNames[this.id], loop);
  }
  startStopAnimation() {
    this.animation.visible = true;
    this.animation.skeleton.setToSetupPose();
    this.animation.state.setAnimation(0, this.stopAnimationNames[this.id], false);
  }

  _complete() {
    // this.animation.visible = false;
    this.onComplete();
    // this.emit('animationComplete', this);
  }

  stop() {
    if (this.animation) {
      this.animation.visible = false;
    }
  }
}
