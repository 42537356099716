import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { goldFontStyle, winFontStyle } from '../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { shadowText } from 'Engine/utils/fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import SoundManager from 'Engine/soundManager/SoundManager';
import LocalizeTextField from '../libs/game-engine/src/base/localization/LocalizeTextField';
import UiAdapter from '../ui/uiAdapterExtended';
import { uiConfig } from '../ui/config';
import {eSounds} from "../enums/eSoundTypes";

export default class StartScreenContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.onKeyDown = this.onKeyDown.bind(this);

    this.init();
    this.addListeners();
  }

  onResize(data) {
    if (data.isLandscape) {
      this.background.goToFrame(2);
      this.content.visible = true;
      this.portretContent.visible = false;
      this.startButton.pivot.x = 0;
      this.startButton.x = this.startButton.displayData.x;
      this.startButton.scale.set(this.startButton.displayData.scaleX);
      this.startButton.y = this.startButton.displayData.y;
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
      this.content.visible = false;
      this.portretContent.visible = true;
      this.startButton.pivot.x = this.startButton.displayData.width / 2;
      this.startButton.x = this.startButton.displayData.x  + this.startButton.displayData.width / 2;
      this.startButton.scale.set(1.25);
      this.startButton.y = this.startButton.displayData.y + 300;
    }
  }

  init() {
    this.content = this.getChildByName('content');
    this.portretContent = this.getChildByName('portretContent');
    this.background = this.getChildByName('background');
    this.visible = false;
    this.eventMode = 'static';
    this.startButton = this.getChildByName('startButton');
    makeHitArea(this.startButton);
    this.setTextsStyle();
    this.setWinAmounts();
  }

  addListeners() {
    GlobalDispatcher.addOnce('startScreen:show', this.show.bind(this));
    this.startButton.on('pointertap', this.hide, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  setTextsStyle() {
    const setFunction = (content) => {
      for (let i = 0; i < 10; i++) {
        const text = content.getChildByName(`start_screen_${i}`);
        text.style = { ...text.style, ...goldFontStyle };
          if (i === 8) text.text = window.OPWrapperService.localizations.getLocalizedText('start_screen_6');
          if (i === 0) text.fitSize(true, true);
      }
      const winAmount = content.getChildByName(`start_screen_max_win_amount`);
      winAmount.style = { ...winAmount.style, ...goldFontStyle };
    }
    const startButtonText = this.startButton.getChildByName('start_screen_play');
    startButtonText.style = { ...startButtonText.style, ...shadowText };
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);
  }

  setWinAmounts() {
    const setFunction = (content) => {
      const maxWinField = content.getChildByName(`start_screen_max_win_amount`);
      maxWinField.text = MoneyFormat.format(EntryPoint.GameModel.jackpotMaxWin, true);
      maxWinField.correctPosition = function () {
        LocalizeTextField.prototype.correctPosition.call(this, 'center', 'center');
      };
      maxWinField.fitSize();
    };
    setFunction(this.content);
    if (EntryPoint.mobile) setFunction(this.portretContent);
  }

  onKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.hide();
    }
  }

  show() {
    document.addEventListener('keydown', this.onKeyDown);
    this.startButton.enabled = true;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.startButton.enabled = false;
    GlobalDispatcher.dispatch('startScreen:hide');
    if (EntryPoint.GameModel.isBonusGameMode) {
      SoundManager.play(eSounds.EST_BONUS_GAME_BACKGROUND.soundName, eSounds.EST_BONUS_GAME_BACKGROUND.volume, true, eSounds.EST_BONUS_GAME_BACKGROUND.group);
    } else {
      SoundManager.play(eSounds.EST_BACKGROUND.soundName, eSounds.EST_BACKGROUND.volume, true, eSounds.EST_BACKGROUND.group);
    }
    document.removeEventListener('keydown', this.onKeyDown);
    OPWrapperService.freeBetsController.show();

    const ControllerUi = window.OPWrapperService.initUi(uiConfig);
    new UiAdapter(ControllerUi).init({
      bonusLabelText: 'RESPINS',
      clickSound: eSounds.EST_BUTTON_CLICK
    });
  }
}
