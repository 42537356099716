import { goldFontStyle } from '../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import BaseMoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import SoundManager from 'Engine/soundManager/SoundManager';
import { gsap } from 'gsap';
import { eSounds } from '../../enums/eSoundTypes';

export default class ControllerBonusGameEnd extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init();
  }

  init() {
    super.init();
    this.totalWinText = this.getChildByName('popup_bonus_game_end_total_win');
    this.textFieldTotalWin = this.getChildByName('amount');
    this.setTextStile();
  }

  setTextStile() {
    this.totalWinText.style = { ...this.totalWinText.style, ...goldFontStyle };
    this.textFieldTotalWin.style = {
      ...this.textFieldTotalWin.style,
      ...goldFontStyle,
    };
  }

  onShow() {
    super.onShow();
    this.totalWin = 0;
    const arrForDecimals = BaseMoneyFormat.formatCurrency(EntryPoint.GameModel.bonusGameTotalWin, EntryPoint.GameModel.currencyInfo.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;
    gsap.to(this, {
      totalWin: EntryPoint.GameModel.bonusGameTotalWin,
      duration: 2,
      onComplete: () => {
        setTimeout(() => {
            this.hide();
            this.textFieldTotalWin.text = BaseMoneyFormat.format(EntryPoint.GameModel.bonusGameTotalWin);
          }
          , 2000)
      }
    })
    SoundManager.play(eSounds.EST_FS_TOTAL_WIN.soundName, eSounds.EST_FS_TOTAL_WIN.volume, false, eSounds.EST_FS_TOTAL_WIN.group);
  }

  onHide() {
    super.onHide();
    GlobalDispatcher.dispatch('freespinsEnd: onComplete')
    this.stopBonusGameBackgroundSound();
  }

  set totalWin(value) {
    this._totalWin = value
    this.textFieldTotalWin.text = `${value.toFixed(this.decimalsForAnimation)} ${EntryPoint.GameModel.currencyInfo.currency}`;
  }

  get totalWin() {
    return this._totalWin;
  }

  stopBonusGameBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSounds.EST_BACKGROUND.soundName);
    const bonusGameBackground = SoundManager.getSound(eSounds.EST_BONUS_GAME_BACKGROUND.soundName);
    const timeline = gsap.timeline();
    timeline
      .to(bonusGameBackground, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.stop(eSounds.EST_BONUS_GAME_BACKGROUND.soundName);
        SoundManager.play(eSounds.EST_BACKGROUND.soundName, eSounds.EST_BACKGROUND.volume, true, eSounds.EST_BACKGROUND.group);
      })
      .fromTo(backgroundMainGameSound, { volume: 0 }, { volume: 0.5, duration: 1.5 })
  }
}
