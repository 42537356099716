import FlashLib from 'flashlib_onlyplay';
import SoundManager from 'Engine/soundManager/SoundManager';
import EntryPoint from 'Engine/EntryPoint';
import { gsap } from 'gsap';
import {eSounds} from "../../enums/eSoundTypes";

export default class ControllerJackpotGame extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.visible = false;
    this.alpha = EntryPoint.GameModel.isJackpotGame ? 1 : 0;

    this.init();
    this.addListeners();
  }
  init() {
    this.background = this.getChildByName('background');
    this.totalWinPanel = this.getChildByName('totalWinPanel');
    this.panels = this.getChildByName('panels');
    this.field = this.getChildByName('field');
    this.field.init(this.panels);
    this.eventMode = 'static';
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize(data) {
    if (data.isLandscape) {
      this.background.goToFrame(2);
      this.totalWinPanel.anchorDefaultX();
      this.totalWinPanel.y = this.totalWinPanel.displayData.y;
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1);
      this.totalWinPanel.centringX();
      this.totalWinPanel.y = this.totalWinPanel.displayData.y + 200;
    }
  }

  show() {
    this.panels.reset();
    this.field.create();
    this.visible = true;
    this.playJackpotBackgroundSound();
    gsap.to(this, { alpha: 1, duration: 0.6 });
  }

  playJackpotBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(
      eSounds.EST_BONUS_GAME_BACKGROUND.soundName
    );
    const jackpotBackground = SoundManager.getSound(
      eSounds.EST_JACKPOT_GAME_BACKGROUND.soundName
    );
    const timeline = gsap.timeline();
    timeline
      .to(backgroundMainGameSound, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.pause(eSounds.EST_BONUS_GAME_BACKGROUND.soundName);
        SoundManager.play(
          eSounds.EST_JACKPOT_GAME_BACKGROUND.soundName,
          eSounds.EST_JACKPOT_GAME_BACKGROUND.volume,
          true,
          eSounds.EST_JACKPOT_GAME_BACKGROUND.group
        );
      })
      .fromTo(jackpotBackground, { volume: 0 }, { volume: 0.9, duration: 1.5 });
  }

  stopJackpotBackgroundSound() {
    const backgroundMainGameSound = SoundManager.getSound(
      eSounds.EST_BONUS_GAME_BACKGROUND.soundName
    );
    const jackpotBackground = SoundManager.getSound(
      eSounds.EST_JACKPOT_GAME_BACKGROUND.soundName
    );
    const timeLine = gsap.timeline();
    timeLine
      .to(jackpotBackground, { volume: 0, duration: 0.5 })
      .call(() => {
        SoundManager.stop(eSounds.EST_JACKPOT_GAME_BACKGROUND.soundName);
        SoundManager.play(eSounds.EST_BONUS_GAME_BACKGROUND.soundName, eSounds.EST_BONUS_GAME_BACKGROUND.volume, true, eSounds.EST_BONUS_GAME_BACKGROUND.group);
      })
      .fromTo(backgroundMainGameSound , { volume: 0 }, { volume: 0.5, duration: 1.5 });
  }

  hide() {
    this.stopJackpotBackgroundSound();
    gsap.to(this, {
      alpha: 0,
      duration: 0.6,
      onComplete: () => (this.visible = false),
    });
  }
}
