import BaseState from "Engine/base/states/BaseState";
import eAnimationTypes from '../animations/animationTypes';
import eGameStateTypes from '../libs/op-wrapper-service/infoPanel/items/GameState/eGameStateTypes';

export default class showWonJackpotGameState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    async start() {
        OPWrapperService.infoPanel.setGameState(eGameStateTypes.EGST_JACKPOT_GAME);
        console.log('start showWonJackpotGameState' )
        if (!PIXI.Assets.get(eAnimationTypes.JACKPOT)) {
            OPPreloader.showDelayedPreloader();
            await PIXI.Assets.load(eAnimationTypes.JACKPOT);
            OPPreloader.hideDelayedPreloader();
        }
        super.start();
    }
}
