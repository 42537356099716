export default {
  EST_REEL_STOP: 'ReelStop1',
  EST_SCATTER_STOP: 'ScatterStop',
  EST_BIG_WIN: 'BigWin',
  EST_MEGA_WIN: 'SuperWin',
  EST_BUTTON_CLICK: 'ButtonClick',
  EST_MONEY_GAIN: 'MoneyGain',
  EST_TILE: 'Tile',
  EST_SIMPLE_WIN_0: 'simpleWin_0',
  EST_SIMPLE_WIN_1: 'simpleWin_1',
  EST_SIMPLE_WIN_2: 'simpleWin_2',
  EST_SIMPLE_WIN_3: 'simpleWin_3',
  EST_SIMPLE_WIN_4: 'simpleWin_4',
  EST_FS_TOTAL_WIN: 'total_win',
  EST_REEL_ANTISIPATOR: 'antisip_reel_start',
  EST_REEL_ANTISIPATOR_TILE: 'antisip_reels_finish',
  EST_BACKGROUND: 'SFX2',
  EST_BONUS_GAME_BACKGROUND: 'bonus_game_background',
  EST_START_BOUNS_GAME: 'startBounsGame',
  EST_JOKER: 'joker',
  EST_FLY_COIN_SYMBOL: 'flyCoinSymbol',
  EST_FLY_COIN_VALUE: 'flyCoinValue',
  EST_JACKPOT_GAME_BACKGROUND: 'jackpotGame/background',
  EST_WIN_JACKPOT_GAME: 'jackpotGame/winJackpotGame',
  EST_JACKPOT_GAME_CLICK_ON_PLATE: 'jackpotGame/clickOnPlate',
  EST_JACKPOT_GAME_JACKPOT: 'jackpotGame/jackpot',
  EST_JACKPOT_GAME_PLATE_DESTROYED: 'jackpotGame/plateDestroyed',
}
