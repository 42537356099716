import BaseButton from 'Engine/base/ui/BaseButton';
import SoundManager from 'Engine/soundManager/SoundManager';
import {eSounds} from "../enums/eSoundTypes";

export default class BaseGameButton extends BaseButton {
  constructor(...arg) {
    super(...arg);
    this.on('pointertap', () => SoundManager.play(eSounds.EST_BUTTON_CLICK.soundName, eSounds.EST_BUTTON_CLICK.volume, false, eSounds.EST_BUTTON_CLICK.group))
  }
}
