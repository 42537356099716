import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import en from '../../assets/localization/en';
import enSocial from '../../assets/localization/en-social';
import {eSounds} from "../enums/eSoundTypes";

export default {
  buttons: [
    {
      type: 'settings',
      onClick: onSettingsClick,
      styles: ['settings']
    },
  ],
  bundle: 'jokerscoins',
  localizationBundle: 'jokerscoins',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};

function onSettingsClick() {
  SoundManager.play(eSounds.EST_BUTTON_CLICK.soundName);
  GlobalDispatcher.dispatch(eEventTypes.EET_SETTINGS__CLICK);
}
