import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { jacpotPanelsAmountFontStyles } from '../../fontStyles';
import LocalizeTextField from '../../libs/game-engine/src/base/localization/LocalizeTextField';

export default class ControllerJackpotPanels extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.ePanelTypes = {
      EPT_GRAND: 'grand',
      EPT_MEGA: 'mega',
      EPT_MAJOR: 'major',
      EPT_MINOR: 'minor',
      EPT_MINI: 'mini',
    };
    this.timeouts = [];
    this.init();
    this.setTextStile();
    this.updatePanels();
    this.addListeners();
  }

  init() {
    this.panels = [];
    this.grandPanel = this.getChildByName('grand');
    this.grandPanel.type = this.ePanelTypes.EPT_GRAND;
    this.panels.push(this.grandPanel);
    this.grandPanelAmount = this.grandPanel.getChildByName('textAmount');
    this.grandPanelTitle = this.grandPanel.getChildByName(
      'Jackpot_panel_title_grand'
    );
    this.megaPanel = this.getChildByName('mega');
    this.megaPanel.type = this.ePanelTypes.EPT_MEGA;
    this.panels.push(this.megaPanel);
    this.megaPanelAmount = this.megaPanel.getChildByName('textAmount');
    this.megaPanelTitle = this.megaPanel.getChildByName(
      'Jackpot_panel_title_mega'
    );
    this.majorPanel = this.getChildByName('major');
    this.majorPanel.type = this.ePanelTypes.EPT_MAJOR;
    this.panels.push(this.majorPanel);
    this.majorPanelAmount = this.majorPanel.getChildByName('textAmount');
    this.majorPanelTitle = this.majorPanel.getChildByName(
      'Jackpot_panel_title_major'
    );
    this.minorPanel = this.getChildByName('minor');
    this.minorPanel.type = this.ePanelTypes.EPT_MINOR;
    this.panels.push(this.minorPanel);
    this.minorPanelAmount = this.minorPanel.getChildByName('textAmount');
    this.minorPanelTitle = this.minorPanel.getChildByName(
      'Jackpot_panel_title_minor'
    );
    this.miniPanel = this.getChildByName('mini');
    this.miniPanel.type = this.ePanelTypes.EPT_MINI;
    this.panels.push(this.miniPanel);
    this.miniPanelAmount = this.miniPanel.getChildByName('textAmount');
    this.miniPanelTitle = this.miniPanel.getChildByName(
      'Jackpot_panel_title_mini'
    );
    this.startAnimations();
  }

  addListeners() {
    GlobalDispatcher.add('model:betChanged', this.updatePanels, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  onResize(data) {
    if (data.isLandscape) {
      this.goToFrame(2);
      this.init();
      this.setTextStile();
      this.updatePanels();
    } else if (data.isMobile && data.isPortrait) {
      this.goToFrame(1);
      this.init();
      this.setTextStile();
      this.updatePanels();
    }
  }
  setTextStile() {
    this.grandPanelAmount.style = {
      ...this.grandPanelAmount.style,
      ...jacpotPanelsAmountFontStyles,
    };
    this.megaPanelAmount.style = {
      ...this.megaPanelAmount.style,
      ...jacpotPanelsAmountFontStyles,
    };
    this.majorPanelAmount.style = {
      ...this.majorPanelAmount.style,
      ...jacpotPanelsAmountFontStyles,
    };
    this.minorPanelAmount.style = {
      ...this.minorPanelAmount.style,
      ...jacpotPanelsAmountFontStyles,
    };
    this.miniPanelAmount.style = {
      ...this.miniPanelAmount.style,
      ...jacpotPanelsAmountFontStyles,
    };
  }

  updatePanels() {
    [
      this.miniPanelAmount,
      this.minorPanelAmount,
      this.majorPanelAmount,
      this.megaPanelAmount,
      this.grandPanelAmount,
    ].forEach((panel, index) => {
      panel.text = MoneyFormat.format(
        EntryPoint.GameModel.jackpotList[index],
        true
      );
      panel.correctPosition = function () {
        LocalizeTextField.prototype.correctPosition.call(
          this,
          'center',
          'center'
        );
      };
      panel.fitSize(true);
    });
  }

  startAnimations() {
    const offset = 200;
    this.clearTimeouts();
    const timeout = setInterval(() => {
      this.panels.forEach((panel, index) => {
        const timeout = setTimeout(() => {
          panel.startAnimation();
        }, index * offset);
        this.timeouts.push(timeout);
      });
    }, 24000);
    this.timeouts.push(timeout);
  }

  clearTimeouts() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts = [];
  }

  destroy() {
    this.clearTimeouts();
    super.destroy({ children: true });
  }
}
