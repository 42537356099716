import { goldFontStyle } from '../../fontStyles';
import BasePopup from '../popupWin/BasePopup';
import { gsap } from 'gsap';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../enums/eSoundTypes';

export default class ControllerBonusGameStart extends BasePopup {
  constructor(data, displayData) {
    super(data, displayData);
    this.alpha = 0;
    this.init();
  }

  init() {
    super.init();
    this.youWinText = this.getChildByName('bonus_game_popup_start_you_win');
    this.setTextStile();
  }

  setTextStile() {
    this.youWinText.style = { ...this.youWinText.style, ...goldFontStyle };
  }

  onShow() {
    super.onShow();
    setTimeout(() => {
        this.hide();
      }
      , 3000)
    SoundManager.play(eSounds.EST_START_BOUNS_GAME.soundName, eSounds.EST_START_BOUNS_GAME.volume, false, eSounds.EST_START_BOUNS_GAME.group);
    this.stopMainSound()
  }

  onHide() {
    super.onHide();
    GlobalDispatcher.dispatch('freespinsStart: onComplete');
    this.playBonusGameBackgroundSound();
  }

  stopMainSound() {
    const backgroundMainGameSound = SoundManager.getSound(eSounds.EST_BACKGROUND.soundName);
    gsap.to(backgroundMainGameSound, {
      volume: 0,
      duration: 0.5,
      onComplete: () => SoundManager.pause(eSounds.EST_BACKGROUND.soundName)
    });
  }

  playBonusGameBackgroundSound() {
    const bonusGameBackground = SoundManager.getSound(eSounds.EST_BONUS_GAME_BACKGROUND.soundName);
    SoundManager.play(eSounds.EST_BONUS_GAME_BACKGROUND.soundName, eSounds.EST_BONUS_GAME_BACKGROUND.volume, true, eSounds.EST_BONUS_GAME_BACKGROUND.group);
    gsap.fromTo(bonusGameBackground, { volume: 0 }, { volume: 0.5, duration: 0.5 });
  }
}
